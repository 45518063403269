import styled from "styled-components";

export const LinktreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
`

export const LinktreeHeader = styled.div`
  border-bottom: 1px solid #E2E4E9;
  padding: 30px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  min-height: 40vh;

  button {
    background: none;
    border: 1px solid grey;
    color: black;
  }
`

export const ProfileImage = styled.div`
  background: url('${props => props.image}') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid #8BF4CD;
`

export const LinktreeBody = styled.div`
  margin: 30px 0px;
  padding: 0px 10px;
  
  .layout-buttons {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    gap: 1rem;
    background: #E7FAF3;

    a {
      border: 1px solid #E2E4E9;
      padding: 20px;
      text-align: center;
      border-radius: 10px;
      font-size: 16px;
      background: white;
      color: #142C1F;
      cursor: pointer;
    }
  }
`

export const LinktreeFooter = styled.div`
  padding: 20px;
  background: white;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    img {
      width: 100px;
    }
  }
  
  .partners {
    margin-bottom: 20px;

    p {
      font-weight: bold;
      text-align: center;
    }
    
    .partner-logos {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 1.5rem;
      margin-top: 10px;

      > * {
        border-radius: 10px;
        box-shadow: 0 0 5px lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }

      img {
        width: 100%;
        filter: grayscale(100%);
      }
    }
  }

  .links {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      margin-right: 10px;
      color: #818D88;
    }
  }
`

export const Qualifications = styled.div`
  padding: 20px;

  .headline {
    display: flex;
    align-items: center;

    p {
      width: 100%;
    }

    .back-button {
      margin-left: -3px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .qualifications-list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    border-radius: 10px;
    gap: 1rem;
    background: #E7FAF3;

    .qualification {
      display: flex;
      flex-direction: column;
      border: 1px solid #E2E4E9;
      padding: 10px;
      border-radius: 10px;
      background: white;
      color: #142C1F;
    }
  }
`
