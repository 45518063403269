import React from "react";
import * as C from "./firstConsultation.styled";
import Chip from "components/General/formComponents/chip/Chip";
// Images
import FoodiaryErstgesprach from "assets/images/pngs/Hands-Foodiary-Coaching.png";

const FirstConsultation = () => {
  return (
    <C.CoachingAccordianContainer>
      <C.CoachingAccordianRight>
        <img src={FoodiaryErstgesprach} alt="consultation" />
      </C.CoachingAccordianRight>
      <C.CoachingAccordianLeft>
        <Chip text="ERSTGESPRÄCH" margin="0 0 3rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Ist das Erstgespräch wirklich kostenlos?
        </C.Title>
        <C.Info className="ct-text-block color-paragraph text-base text-left">
          Ja! Ich möchte dir hiermit einen unverbindlichen Einblick in eine
          mögliche Zusammenarbeit geben. Dank der Vision von Foodiary, die
          Ernährungsumstellung für jeden so einfach wie möglich zu gestalten,
          kann das 45-minütige Erstgespräch kostenlos angeboten werden.
        </C.Info>
        <C.Info className="ct-text-block color-paragraph text-base text-left">
          Du bist motiviert und willst direkt starten? Buche direkt das Coaching
          PRO mit mir. Du erhältst dadurch Vorrang bei der Terminvergabe und
          kannst direkt für den morgigen Tag deinen Starttermin buchen.
        </C.Info>
      </C.CoachingAccordianLeft>
    </C.CoachingAccordianContainer>
  );
};

export default FirstConsultation;
