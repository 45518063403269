import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./Personality.styled";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CentralShape from "assets/images/pngs/central-shape.png";

const Personality = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_fm_picture_2
    ? getS3ImageUrl(coach?.cw_fm_picture_2)
    : Coach;

  return (
    <C.Wrap>
      <C.VisualContent>
        {coach?.cw_fm_picture_2 && (
          <C.Image maskImage={CentralShape}>
            <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
          </C.Image>
        )}
        {!coach?.cw_fm_picture_2 && (
          <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        )}
        {/* <C.Image>
          {coach?.cw_fm_picture_2 && (
            <img className="shape" src={ShapeOutline} alt="Shape" />
          )}

          <img className={coach?.cw_fm_picture_2 ? "img" : 'img h-auto'} src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        </C.Image> */}
        {/* <C.Image>
          <img className="shape" src={ShapeOutline} alt="Shape" />
          <img className="img" src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        </C.Image> */}
      </C.VisualContent>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 4rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Persönlichkeit statt Standard-Programm
        </C.Title>

        <p className="ct-text-block color-paragraph text-base text-left mt-6">
          Bei meinem Coaching PRO handelt es sich um kein vorgefertigtes
          Programm, sondern um eine 100% persönliche Ernährungsbetreuung. Du
          erhältst einen persönlichen Ernährungsplan mit über 1700 leckeren
          Rezepten und mich als festen Ansprechpartner für die kommenden 3
          Monate deiner Ernährungsumstellung.
        </p>

        <C.PlanBoxIcons className="">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Plan wird persönlich für dich erstellt.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Über 1700 abwechslungsreiche Rezepte
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Persönlicher Ernährungsberater an deiner Seite
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  );
};

export default Personality;
