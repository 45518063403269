import { useState, useEffect } from "react";
import LeadsSurveyForm from "./LeadsSurvey/LeadsSurveyForm";

import Close from "assets/images/myCoaching/Icon-Close-black.svg";

import * as C from "constants";
import * as M from "./LeadSurveyModal.style";

const LeadSurveyModal = ({ isOpen, onClose, coachProfile }) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    if (!isOpen) document.body.style.overflow = "unset";
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <M.ModalContainer id="myModal" className="modal-container">
          <M.Modal className="modal-c d-block">
            <M.ModalHeader className="modal-header border-0">
              <M.Close className="close" onClick={onClose}>
                <img src={Close} alt="close" />
              </M.Close>
            </M.ModalHeader>
            <M.ModalContent className="modal-content border-0">
              <LeadsSurveyForm coach={coachProfile} onModalClose={onClose}/>
            </M.ModalContent>
          </M.Modal>
        </M.ModalContainer>
      )}
    </>
  );
};

export default LeadSurveyModal;
