import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import * as F from "./whyIamYourCoach.style";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import DeafultImage from "assets/images/DefaultCoachbin.jpg";
import CentralShape from "assets/images/pngs/central-shape.png";

const WhyIamYourCoach = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_lp_picture_2
    ? getS3ImageUrl(coach?.cw_lp_picture_2)
    : DeafultImage;

  return (
    <F.WhyIamYourCoachWrap>
      <F.VisualContent>
        {coach?.cw_lp_picture_2 && (
          <F.Image maskImage={CentralShape}>
            <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
          </F.Image>
        )}
        {!coach?.cw_lp_picture_2 && (
          <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        )}
      </F.VisualContent>
      <F.ContentSection>
        <Chip text="ÜBER MICH" />
        <F.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Wieso ich dein Coach bin?
        </F.Title>
        <F.Description
          className="ct-text-block color-paragraph text-base text-left"
          dangerouslySetInnerHTML={{ __html: coach?.cw_lp_textfield_2 }}
        />
      </F.ContentSection>
    </F.WhyIamYourCoachWrap>
  );
};

export default WhyIamYourCoach;
