import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import emailjs from "@emailjs/browser";
import * as C from "./FreeInitialConsultationWithSurvey.style";

import Chip from "components/General/formComponents/chip/Chip";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import LeadsSurveyForm from "components/LeadsSurvey/LeadsSurveyForm";

const FreeInitialConsultationWithSurvey = () => {
  const coach = useSelector((state) => state.coach.coach);
  const [CurrentStep, setCurrentStep] = useState(1);

  const [SelectedStepOne, setSelectedStepOne] = useState(null);
  const [SelectedStepTwo, setSelectedStepTwo] = useState(null);
  const [SelectedStepThree, setSelectedStepThree] = useState(null);

  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Message, setMessage] = useState("");
  const [IsSending, setIsSending] = useState(false);
  const [EmailHasSent, setEmailHasSent] = useState(false);

  const STEP_ONE = [
    { id: 1, title: "Gewichtsabnahme" },
    { id: 2, title: "Muskelaufbau" },
    { id: 3, title: "Gesunder Lebensstil" },
  ];

  const STEP_TWO = [
    { id: 1, title: "Männlich" },
    { id: 2, title: "Weiblich" },
    { id: 3, title: "Keine Angabe" },
  ];

  const STEP_THREE = [
    { id: 1, title: "18-21 Jahre" },
    { id: 2, title: "22-25 Jahre" },
    { id: 3, title: "26-35 Jahre" },
    { id: 4, title: "36-45 Jahre" },
    { id: 5, title: "45+ Jahre" },
  ];

  const onItemSelect = (step, selectedItem) => {
    if (step === 1) {
      setSelectedStepOne(selectedItem);
    }

    if (step === 2) {
      setSelectedStepTwo(selectedItem);
    }

    if (step === 3) {
      setSelectedStepThree(selectedItem);
    }
  };

  const onNext = (step) => {
    switch (step) {
      case 1:
        if (SelectedStepOne !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 2:
        if (SelectedStepTwo !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 3:
        if (SelectedStepThree !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 4:
        if (Message !== "" && Email !== "" && PhoneNumber !== "") {
          sendEmail();
        }
        break;
      default:
        break;
    }
  };

  const onPrev = (step) => {
    setCurrentStep(step - 1);
  };

  const sendEmail = () => {
    setIsSending(true);
    const payload = {
      valueOne: SelectedStepOne?.title,
      valueTwo: SelectedStepTwo?.title,
      valueThree: SelectedStepThree?.title,
      valueFour: Message,
      valueFive: `${Email} - ${PhoneNumber}`,
      toEmail: coach?.email,
      fromName: FullName,
      fromEmail: Email,
    };
    const IDS = {
      service: process.env.REACT_APP_EMAIL_SERVICE_ID,
      template: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY_ID,
    };
    emailjs.send(IDS.service, IDS.template, payload, IDS.publicKey).then(
      (result) => {
        setIsSending(false);
        setEmailHasSent(true);
        resetForm();
        const timer = setTimeout(() => {
          setEmailHasSent(false);
          clearTimeout(timer);
        }, 3000);
      },
      (error) => {
        setIsSending(false);
      }
    );
  };

  const resetForm = () => {
    setPhoneNumber("");
    setEmail("");
    setMessage("");
    setFullName("");
  };

  return (
    <C.Wrap>
      <C.ContentSection>
        <Chip text="DEIN KOSTENLOSES ERSTGESPRÄCH" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Mit meiner Unterstützung erreichst du dein Ziel 🙌
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          Bitte fülle dieses Formular aus, damit ich genau weiß, wie ich dir auf
          deiner Abnehmreise am besten helfen kann.
        </C.Description>
        <C.PlanBoxIcons className="procedure">
          {[
            "Lerne mich in einem kostenlosem und unverbindlichem Video-Call kennen.",
            "Erstelle gemeinsam mit mir deinen persönlichen Ernährungsplan.",
            "Stell mir deine Fragen rund um das Thema Ernährung, Ernährungsplanung und Coaching. Profitiere von meinem Expertenwissen.",
          ].map((t) => (
            <li>
              <C.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </C.PlanBoxIconBox>
              <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
                {t}
              </C.PlanBoxInformation>
            </li>
          ))}
        </C.PlanBoxIcons>
      </C.ContentSection>
      <LeadsSurveyForm />
      {/* <C.SurveyForm>
        <C.SurveyFormCard>
          <div className="inner-box">
            {CurrentStep === 1 && (
              <>
                <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                  Wie lautet deine Zielsetzung?
                </C.SurveyFormTitle>
                {STEP_ONE.map((item) => (
                  <C.FormItem
                    active={SelectedStepOne?.id === item?.id}
                    onClick={() => onItemSelect(1, item)}
                  >
                    {item?.title}
                  </C.FormItem>
                ))}

                <C.Actions>
                  <button
                    className="ct-link-text foodiary-green-button cursor-pointer"
                    onClick={() => onNext(1)}
                  >
                    Weiter
                  </button>
                </C.Actions>
              </>
            )}
            {CurrentStep === 2 && (
              <>
                <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                  Wie lautet deine Zielsetzung?
                </C.SurveyFormTitle>
                {STEP_TWO.map((item) => (
                  <C.FormItem
                    active={SelectedStepTwo?.id === item?.id}
                    onClick={() => onItemSelect(2, item)}
                  >
                    {item?.title}
                  </C.FormItem>
                ))}

                <C.Actions>
                  <button className="btn-prev" onClick={() => onPrev(2)}>
                    Zurück
                  </button>
                  <button
                    className="ct-link-text foodiary-green-button cursor-pointer"
                    onClick={() => onNext(2)}
                  >
                    Weiter
                  </button>
                </C.Actions>
              </>
            )}
            {CurrentStep === 3 && (
              <>
                <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                  Wie lautet deine Zielsetzung?
                </C.SurveyFormTitle>
                {STEP_THREE.map((item) => (
                  <C.FormItem
                    active={SelectedStepThree?.id === item?.id}
                    onClick={() => onItemSelect(3, item)}
                  >
                    {item?.title}
                  </C.FormItem>
                ))}

                <C.Actions>
                  <button className="btn-prev" onClick={() => onPrev(3)}>
                    Zurück
                  </button>
                  <button
                    className="ct-link-text foodiary-green-button cursor-pointer"
                    onClick={() => onNext(3)}
                  >
                    Weiter
                  </button>
                </C.Actions>
              </>
            )}

            {CurrentStep === 4 && (
              <>
                <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                  Wie lautet deine Zielsetzung?
                </C.SurveyFormTitle>
                {EmailHasSent && (
                  <C.Statusbar>Email has sent successfully!</C.Statusbar>
                )}

                <C.PhoneNumberInput
                  type="text"
                  placeholder="Vorname, Nachname"
                  value={FullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
                <C.PhoneNumberInput
                  type="text"
                  placeholder="Kontaktnummer"
                  value={PhoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
                <C.EmailInput
                  type="email"
                  placeholder="E-Mail"
                  value={Email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <C.Textarea
                  type="text"
                  placeholder="Deine Nachricht an mich"
                  rows={5}
                  value={Message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <C.Actions>
                  <button
                    className="btn-prev"
                    disabled={IsSending}
                    onClick={() => onPrev(4)}
                  >
                    Zurück
                  </button>
                  <button
                    className="ct-link-text foodiary-green-button cursor-pointer"
                    onClick={() => onNext(4)}
                    disabled={IsSending}
                  >
                    Weiter
                  </button>
                </C.Actions>
              </>
            )}
          </div>
        </C.SurveyFormCard>
      </C.SurveyForm> */}
    </C.Wrap>
  );
};

export default FreeInitialConsultationWithSurvey;
