import React from "react";
import ReactPlayer from "react-player";

import * as F from "./ConsciousNutritionCourse.style";

const ConsciousNutritionCourse = () => {
  return (
    <>
      <F.CoachingWithMeContainer>
        <div id="div_block-4-9" className="ct-div-block">
          <div
            id="headline-5-9"
            className="ct-headline color-primary bg-white py-3 px-6 uppercase text-base rounded-full font-medium"
          >
            PRÄVENTION
          </div>
          <div
            id="div_block-6-9"
            className="ct-div-block flex-row relative mt-8 mb-12"
          >
            <div
              id="text_block-7-9"
              className="ct-text-block font-bold color-dark text-left text-5xl"
            >
              Onlinekurs: &nbsp; <br />
              Mit bewusster Ernährung zu mehr Wohlbefinden
            </div>
            <div id="code_block-213-9" className="ct-code-block absolute-tr">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9rem"
                height="8.5rem"
                viewBox="0 0 136.919 134.632"
              >
                <g
                  id="Gruppe_35340"
                  data-name="Gruppe 35340"
                  transform="translate(-687.378 -296.926)"
                >
                  <text
                    id="_"
                    data-name="🍎"
                    transform="translate(763.297 356.926)"
                    fill="#142c1f"
                    font-size="60"
                    font-family="AppleColorEmoji, Apple Color Emoji"
                  >
                    <tspan x="0" y="0">
                      🙌
                    </tspan>
                  </text>
                  <path
                    id="Pfad_30511"
                    data-name="Pfad 30511"
                    d="M766.4,373.926S740.38,407.11,720.145,390.8s40.678-17.551,40.471-3.3S706.1,431.062,688.965,430.03"
                    fill="none"
                    stroke="#a5b5af"
                    stroke-linecap="round"
                    stroke-width="3"
                    stroke-dasharray="5"
                  ></path>
                </g>
              </svg>
            </div>
            <div id="code_block-214-9" className="ct-code-block"></div>
          </div>
          <div
            id="text_block-9-9"
            className="ct-text-block color-paragraph text-base text-left"
          >
            In nur 8 Wochen wirst du mit unserem Online-Kurs lernen, wie du
            deine Ernährung auf eine praxisorientierte und gesunde Weise
            gestalten kannst. Unsere Kursleiter begleiten dich auf dem Weg zu
            einer dauerhaft gesunden Ernährung und unterstützen dich dabei,
            deine Ernährung präventiv zu verbessern.
          </div>
          <div id="div_block-10-9" className="ct-div-block w-full mt-14">
            {[
              "8-Wochen Präventionskurs",
              "Wissensquiz, Lernmaterial & Kursleiter",
              "Deine Krankenkasse zahlt bis zu 100%",
            ].map((t) => (
              <div id="div_block-11-9" className="ct-div-block oxel_iconlist__row">
                <div id="code_block-12-9" className="ct-code-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4.6rem"
                    height="4.6rem"
                    viewBox="0 0 46 46"
                  >
                    <g
                      id="Gruppe_8254"
                      data-name="Gruppe 8254"
                      transform="translate(-702.831 -3690.77)"
                    >
                      <circle
                        id="Ellipse_529"
                        data-name="Ellipse 529"
                        cx="23"
                        cy="23"
                        r="23"
                        transform="translate(702.831 3690.77)"
                        fill="#142c1f"
                      ></circle>
                      <path
                        id="Icon_ionic-ios-checkmark"
                        data-name="Icon ionic-ios-checkmark"
                        d="M30.329,14l-1.635-1.681a.351.351,0,0,0-.26-.111h0a.337.337,0,0,0-.26.111L16.843,23.732l-4.124-4.124a.359.359,0,0,0-.52,0l-1.653,1.653a.37.37,0,0,0,0,.529l5.2,5.2a1.645,1.645,0,0,0,1.087.529,1.724,1.724,0,0,0,1.077-.511h.009L30.338,14.528A.4.4,0,0,0,30.329,14Z"
                        transform="translate(705.396 3693.963)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div
                  id="text_block-13-9"
                  className="ct-text-block foodiary-list-item text-base color-dark font-medium text-left ml-3"
                >
                  {t}
                </div>
              </div>
            ))}
          </div>
        </div>
        <F.VisualContent>
          <ReactPlayer
            url={
              "https://prevention.foodiary.app/wp-content/uploads/2023/05/Prevention-Foodiary-Dashboard-animiert-2.mp4"
            }
            playsinline
            playing
            muted
            loop
          />
        </F.VisualContent>
      </F.CoachingWithMeContainer>
    </>
  );
};

export default ConsciousNutritionCourse;
