import React from "react";
import * as F from "./FreeCourseSteps.style";

import StepCard from "components/General/stepCard/StepCard";
// Images
import Rock from "assets/images/functions/Coach/rock.svg";
import Peace from "assets/images/functions/Coach/peace.svg";
import ThumbsUp from "assets/images/functions/Coach/thumbs-up.svg";

const FreeCourseSteps = () => {
  const List = [
    {
      title: "Anmeldung in wenigen Schritten",
      description:
        "Melde dich für unseren Präventionskurs an und übernehme zuerst die Kosten.",
      icon: ThumbsUp,
    },
    {
      title: "8 Wochen Ernährungskurs",
      description:
        "Lerne alle wichtigen Grundlagen einer praxisorientierter und gesunden Ernährung.",
      icon: Peace,
    },
    {
      title: "Kostenerstattung sichern",
      description:
        "Mit dem Abschlusszertifikat unseres Kurses kannst du bei deiner Krankenkasse eine Kostenerstattung von bis zu 100% beantragen.",
      icon: Rock,
    },
  ];

  return (
    <F.Wrap>
      <F.SectionTitle className="ct-text-block font-bold color-dark text-left text-4xl">
        In 3 Schritten zum kostenfreien Kurs
      </F.SectionTitle>
      {/* <F.SectionTitleMobile className="ct-text-block font-bold color-dark text-left text-4xl">
        STARTE JETZT GEMEINSAM MIT MIR <b>DEIN 12- WOCHEN COACHING!</b>
      </F.SectionTitleMobile> */}
      <F.CardsWrap>
        {List.map((item) => (
          <StepCard
            title={item?.title}
            description={item?.description}
            icon={item?.icon}
          />
        ))}
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default FreeCourseSteps;
