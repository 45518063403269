import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Tabs } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from "axios";
import * as Yup from 'yup';
import * as Styles from './NewLeadsFormSurvey.style.js'
import * as CoachActions from "redux/coach/coach.actions";
import BackArrow from 'assets/images/Icon-Back.svg'
import Check from 'assets/images/check-bullet.svg'
import { parseApiErrors } from 'configs/utils.js';

const getCurrentDateFormatted = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  return `${day}/${month}/${year}`;
}

function BackButton ({ onClick }) {
  return (
    <span className='back-button' onClick={onClick}>
      <img src={BackArrow} alt="" />
    </span>
  )
}

function NewLeadsFormSurvey ({ coachData }) {

  const initialLeadInfo = {
    connected_trainer: coachData.trainerID,
    lead_source: "Coach Webseite",
    lead_received: getCurrentDateFormatted(),
    lead_contacted: "",
    lead_status: "received",
    lead_proposed_date: ""
  }

  const [ leadInfo, setLeadInfo ] = useState(initialLeadInfo)
  const [ activeTabKey, setActiveTabKey ] = useState(0)

  const availableSteps = [
    IdentifyStep,
    ObjectiveStep,
    AgeStep,
    FormStep,
    ThankYouStep
  ]

  const stepProps = {
    nextStep: handleNextStep,
    updateLeadInfo,
    leadInfo,
    coachData,
    backButton: (
      activeTabKey !== 0 && activeTabKey !== availableSteps.length - 1
      ? { show: true, element: <BackButton onClick={handlePrevStep} /> }
      : { show: false }
    )
  }

  const tabItems = availableSteps.map(
    (Step, index) => ({
      key: index,
      children: <Styles.StepContainer>
        <Step {...stepProps} />
      </Styles.StepContainer>
  }))

  function handleNextStep () { setTimeout(() => setActiveTabKey(prev => prev + 1), 500) }
  function handlePrevStep () { setActiveTabKey(prev => prev - 1) }

  function updateLeadInfo (key, value) {
    setLeadInfo({
      ...leadInfo,
      [key]: value
    })
  }

  console.log('leadInfo : ', leadInfo)

  return (
    <Styles.LeadFormContainer>
      <Tabs
        activeKey={activeTabKey}
        defaultActiveKey={activeTabKey}
        items={tabItems}
        renderTabBar={() => null}
      />
    </Styles.LeadFormContainer>
  )
}

export default NewLeadsFormSurvey

function IdentifyStep ({ nextStep, leadInfo, updateLeadInfo, backButton }) {
  const optionsKey = 'lead_gender'
  const selectOptions = [
    'Männlich',
    'Weiblich',
    'Keine Angabe'
  ]

  const stepInfo = {
    title: 'Wie alt bist du?',
    description: 'Mit diesen Angaben kann ich dich unterstützen.',
    backButton
  }



  const saveOption = selectedOption => {
    console.log('selected option : ', optionsKey, selectedOption)
    updateLeadInfo(optionsKey, selectedOption)
    nextStep()
  }

  return (
    <div className='step'>
      <StepHeader {...stepInfo} />
      <FormSelect
        options={selectOptions}
        saveOption={saveOption}
        activeItem={leadInfo[optionsKey]}
      />
    </div>
  )
}

function ObjectiveStep ({ nextStep, leadInfo, updateLeadInfo, backButton }) {

  const optionsKey = 'lead_goal'
  const selectOptions = [
    'Gewichtsabnahme',
    'Muskelaufbau',
    'Gesunder Lebensstil'
  ]

  const saveOption = selectedOption => {
    console.log('selected option : ', optionsKey, selectedOption)
    updateLeadInfo(optionsKey, selectedOption)
    nextStep()
  }

  const stepInfo = {
    title: 'Wie lautet deine Zielsetzung?',
    description: 'Mit diesen Angaben kann ich dich unterstützen.',
    backButton
  }

  return (
    <div className='step'>
      <StepHeader {...stepInfo} />
      <FormSelect
        options={selectOptions}
        saveOption={saveOption}
        activeItem={leadInfo[optionsKey]}
      />
    </div>
  )
}

function AgeStep ({ nextStep, leadInfo, updateLeadInfo, backButton }) {

  const optionsKey = 'lead_age'
  const selectOptions = [
    '18-21 Jahre',
    '22-25 Jahre',
    '26-35 Jahre',
    '36-45 Jahre',
    '45+ Jahre',
  ]

  const saveOption = selectedOption => {
    console.log('selected option : ', optionsKey, selectedOption)
    updateLeadInfo(optionsKey, selectedOption)
    nextStep()
  }

  const stepInfo = {
    title: 'Wie alt bist du?',
    description: 'Mit diesen Angaben kann ich dich unterstützen.',
    backButton
  }

  return (
    <div className='step'>
      <StepHeader {...stepInfo} />
      <FormSelect
        options={selectOptions}
        saveOption={saveOption}
        activeItem={leadInfo[optionsKey]}
      />
    </div>
  )
}

function ThankYouStep ({ backButton }) {

  const stepInfo = {
    title: 'Ich melde mich bei dir',
    description: 'In den nächsten 48 Stunden werde ich mich für eine Terminvereinbarung bei dir melden.',
    backButton
  }

  return (
    <div className='step'>
      <StepHeader {...stepInfo} />
      <div className="thankyou-body">
        <div>
          <span><img src={Check} alt="" /></span>
          <span>Kennenlernen inkl. Anamnese und persönlichen Ernährungsplan</span>
        </div>
        <div>
          <span><img src={Check} alt="" /></span>
          <span>Tipps & Tricks für deine einfache Ernährungsumstellung.</span>
        </div>
        <div>
          <span><img src={Check} alt="" /></span>
          <span>Bis zu 100% Kostenrückerstattung der Krankenkasse auf unser präventives Angebot</span>
        </div>
      </div>
    </div>
  )
}

function FormSelect ({ options, saveOption, activeItem }) {
  return (
    <div className="form-select">
      {options.map(option => (
        <div
          className={activeItem === option ? 'form-option active-item' : 'form-option'}
          key={option}
          onClick={() => saveOption(option)}
        >
          <span>{option}</span>
        </div>
      ))}
    </div>
  )
}

function FormStep ({ nextStep, leadInfo, coachData, backButton }) {

  const dispatch = useDispatch()
  const [ error, setError ] = useState(null)

  const formInitialValues = {
    prename: '',
    surename: '',
    mobilenumber: '',
    email: '',
    policy: false
  }

  const validationSchema = Yup.object({
    prename: Yup.string().required('erforderlich'),
    surename: Yup.string().required('erforderlich'),
    email: Yup.string().email('Ungültig').required('erforderlich'),
    mobilenumber: Yup.string().required('erforderlich'),
    policy: Yup.boolean().oneOf([true], 'erforderlich')
  })

  const handleSubmit = (values) => {
    const apiPayload = {
      ...values,
      ...leadInfo
    }

    dispatch(
      CoachActions.saveTrainerLeadRequest({
        payload: apiPayload,
        callback: leadSavedResponse => {
          if (leadSavedResponse instanceof Error) {
            const parsedErrors = parseApiErrors(leadSavedResponse.response.data)
            let emailErrorMessage = parsedErrors.find(error => error.fieldKey === 'email')
            let errorMessage = emailErrorMessage
              ? 'Deine E-Mail Adresse wurde bereits eingetragen. Bitte kontaktiere mich persönlich per E-Mail.'
              : 'An error accured, please try again.'
            return setError(errorMessage)
          }

          nextStep()

          createCustomerOnTapifiliate(coachData, values, err => {
            if (err) return setError(err)
            console.log('customer created succesfully !');
          })
        },
      })
    )
  }

  const stepInfo = {
    title: 'Deine Kontaktdaten:',
    name: 'form',
    backButton
  }

  return (
    <div className='step'>
      <StepHeader {...stepInfo} />
      <div className="form-lead-info">
        { error ? <div className='error'>{error}</div> : '' }
        <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit} >
          {() => (
            <Form>
              <div>
                <label htmlFor="prename">Vorname</label>
                <Field name="prename" type="text" placeholder="Vorname" />
                <ErrorMessage name="prename" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="surename">Nachname</label>
                <Field name="surename" type="text" placeholder="Nachname" />
                <ErrorMessage name="surename" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="email">E-Mail</label>
                <Field name="email" type="email" placeholder="email@example.com" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="mobilenumber">Telefonnummer</label>
                <Field name="mobilenumber" type="text" placeholder="+49 (0) 1234 567 890 12" />
                <ErrorMessage name="mobilenumber" component="div" className="error" />
              </div>

              <div>
                <span className='policy'>
                  <Field name="policy" type="checkbox" />
                  <span>Ich habe die <a href="#!">AGB</a> und die <a href="#!">Datenschutzbestimmungen</a> gelesen.</span>
                </span>
                <ErrorMessage name="policy" component="div" className="error" />
              </div>

              <button className='btn-dark mt-10' type="submit">Anfragen</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

function StepHeader ({ title, description, backButton, name }) {
  const { show: showBackButton, element } = backButton
  return (
    <>
      <div className="step-header">
        {showBackButton ? (<div>{element}</div>) : ''}
        <div className={name === 'form' ? 'step-title step-title-form' : 'step-title'}>{title}</div>
      </div>
      {description ? <p className='step-description'>{description}</p> : ''}
    </>
  )
}

const createCustomerOnTapifiliate = async (coachData, values, cb) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const refParam = urlParams.get('ref')

  const affilifatePayload = {
    customer_id: values.email,
    meta_data: {
      fullname_coach: coachData?.prename + " " + coachData?.surname,
      email_coach: coachData?.email,
      vorname_klient: values.prename,
      nachname_klient: values.surname,
      email_klient: values.email,
      FranchiseID: coachData?.FranchiseID
    },
    user_agent: navigator.userAgent,
    landing_page: window.location.href,
    referral_code: refParam || coachData.affiliate_code || "ogmwmdy",
    ip: await fetchIpAddress()
  }

  try {
    const response = await axios.post(
      "https://api.tapfiliate.com/1.6/customers/",
      affilifatePayload,
      {
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "3acc178a1eff97b14cc1b12c00222e070a3a1b91",
        }
      }
    )

    console.log("lead added to affiliate program : ", response)
    cb()

  } catch (error) { cb() }
}

const fetchIpAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json")
    return response?.data?.ip
  } catch (error) {
    console.error("Error fetching IP address:", error)
    return null
  }
}
