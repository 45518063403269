import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from './login.styles';

import Topbar from 'components/General/topbar/Topbar';
import Card from 'components/General/card/Card';
import Input from 'components/General/formComponents/input/Input';
import Button from 'components/General/formComponents/button/Button';
import Loader from "components/General/loader/Loader";
import ErrorMessage from 'components/General/formComponents/errorMessage/ErrorMessage';
import TranslationMessage from 'configs/translations/TranslationMessage';


const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [hasExistingPlan, setExistingPlan] = useState(false);
    const authenticated = localStorage.getItem('trainer_token');


    if (authenticated) history.push("/main/dashboard/home")

    const part2Redirection = () => history.push('/nutrition/nutritionForm')

    const initialValues = {
        // coachId: "",
        franchiseId: "",
        // nutritionToken: "",
        // isExisting: hasExistingPlan,
        password: ''
    };

    const validationSchema = Yup.object().shape({
        // coachId: Yup.number().typeError('You must enter a number').required("Required"),
        franchiseId: Yup.string().required("Required"),
        password: Yup.string().required('Required'),
        // isExisting: Yup.boolean(),
        // nutritionToken: Yup.string().when('isExisting', {
        //     is: true,
        //     then: Yup.string().required('Required')
        // }),
    });

    const onFormSubmit = (values, { resetForm }) => {
        const formData = {
            // grant_type: "password",
            // client_id: 2,
            // client_secret: process.env.REACT_APP_CLIENT_SECRET,
            // trainerID: values?.coachId || '',
            FranchiseID: values.franchiseId,
            password: values.password
        };
        // if (hasExistingPlan) {
        //     formData.nutritionconcept_token = values.nutritionToken;
        //     dispatch(AuthActions.signInExistingPlanRequest({ formData: formData, func: part2Redirection }));
        // } else
        // dispatch(AuthActions.signInRequest(formData));
        resetForm();
    };

    return (
        <>
            <Topbar />
            <S.LoginContainer>
                <Card
                    width="400px"
                    height="auto"
                    margin="auto"
                    textAlign="center"
                >
                    <S.LoginInnerContainer>
                        <S.LoginHeading><TranslationMessage id="login.heading" /> </S.LoginHeading>
                        <S.LoginSubHeading><TranslationMessage id="login.subHeading" /></S.LoginSubHeading>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onFormSubmit}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isValid, dirty, }) => (
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    {/* <Input filled type="text" placeholder="CoachID" name="coachId" onChange={handleChange} value={values.coachId} />
                                    <ErrorMessage error={errors.coachId} /> */}
                                    <Input filled type="text" placeholder="FranchiseID" name="franchiseId" onChange={handleChange} value={values.franchiseId} />
                                    <ErrorMessage error={errors.franchiseId} />
                                    <Input filled type="text" placeholder="Passwort" name="password" onChange={handleChange} value={values.password} />
                                    <ErrorMessage error={errors.password} />
                                    {/* {hasExistingPlan && <>
                                        <Input filled type="text" placeholder="Nutrition Token" name="nutritionToken" onChange={handleChange} value={values.nutritionToken} />
                                        <ErrorMessage error={errors.nutritionToken} />
                                    </>}
                                    <Input type="checkbox" name="isExisting" checked={hasExistingPlan} style={{ display: 'none' }} /> */}
                                    <Button color="rgba(248, 164, 146, 1)" margin="20px 0 0 0" type="submit"><TranslationMessage id="button.login" /></Button>
                                    {/* <Loader loading={loading} top="-135px" /> */}
                                </form>
                            )}
                        </Formik>
                    </S.LoginInnerContainer>
                </Card>
                {/* <S.LoginToggleButton onClick={() => setExistingPlan(!hasExistingPlan)} >
                    <TranslationMessage id={hasExistingPlan ? "login.toLogin1" : "login.toLogin2"} />
                </S.LoginToggleButton> */}
            </S.LoginContainer>
        </>
    )
}

export default Login
