import { useState } from "react";
import { Drawer } from 'antd'
import * as Styles from "./Linktree.styles";
import { getS3ImageUrl } from "configs/utils";
import PlaceholderImage from "assets/images/placeholder.jpg";
import FoodiaryLogo from 'assets/images/Foodiary-Logo.png'
import PartnerLogo1 from "assets/images/App/techniker-krankenkasse-logo.svg";
import PartnerLogo2 from "assets/images/App/aok.svg";
import PartnerLogo3 from "assets/images/App/DAK-Gesundheit_logo.svg";
import PartnerLogo4 from "assets/images/App/barmer_krankenkasse_logo.svg";
import PartnerLogo5 from "assets/images/App/ikk-gesundplus.svg";
import NewLeadsFormSurvey from "components/NewLeadsFormSurvey/NewLeadsFormSurvey";
import BackArrow from 'assets/images/Icon-Back.svg'

function BackButton ({ onClick }) {
  return (
    <span className='back-button' onClick={onClick}>
      <img src={BackArrow} alt="" />
    </span>
  )
}

function LinktreeLayout ({ coachData }) {

  console.log('coach data : ', coachData)

  const [ showLeadFormModal, setShowLeadFormModal ] = useState(false)
  const openModal = () => setShowLeadFormModal(true)
  const closeModal = () => setShowLeadFormModal(false)

  const modalOptions = {
    placement: 'bottom',
    width: '100%',
    height: 'auto',
    onClose: closeModal,
    open: showLeadFormModal,
    style: {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      maxHeight: '90vh',
      width: '100%',
      background: 'white',
    },
    styles: {
      header: { display: 'none' },
      body: {
        padding: '0px',
        background: 'white'
      }
    }
  }

  return (
    <>
      <Drawer {...modalOptions} >
        <NewLeadsFormSurvey coachData={coachData.data} />
        <LinktreeFooter hidePartners={true} />
      </Drawer>

      <Styles.LinktreeContainer>
        <LinktreeHeader coachData={coachData} modalOptions={modalOptions} />
        <Styles.LinktreeBody>
          <div className="layout-buttons">
            <a onClick={openModal}>Erstgespräch vereinbaren</a>
            <a href="/online-kurs">Online Ernährungskurs</a>
            <a href="/app">App herunterladen</a>
          </div>
        </Styles.LinktreeBody>

        <LinktreeFooter />
      </Styles.LinktreeContainer>
    </>
  )
}

export default LinktreeLayout

function LinktreeHeader ({ coachData, modalOptions }) {
  const [ showQualification, setShowQualifications ] = useState(false)
  const coachPreviewImage = getS3ImageUrl(coachData.data.profilePic)
  const coachFullName = `${coachData.data.prename} ${coachData.data.surname}`
  const description = coachData.data.description
  const qualifications = coachData.trainer_qualification

  const openModal = () => setShowQualifications(true)
  const closeModal = () => setShowQualifications(false)

  return (
    <Styles.LinktreeHeader>
      <Drawer {...modalOptions} open={showQualification} onClose={closeModal} >
        <CoachQualifications onClose={closeModal} qualifications={qualifications} />
        <LinktreeFooter hidePartners={true} />
      </Drawer>
      <Styles.ProfileImage image={coachPreviewImage || PlaceholderImage} />
      <span className="font-bold color-dark text-3xl">{coachFullName}</span>
      <p className="color-paragraph text-base text-center">{description || 'Als dein Foodiary Coach unterstütze ich dich, deine Ernährung im Alltag umzustellen.'}</p>
      <button onClick={openModal} className="btn-light text-base text-dark">Meine Qualifikationen</button>
    </Styles.LinktreeHeader>
  )
}

function CoachQualifications ({ qualifications, onClose }) {
  if (!qualifications || !qualifications.length) return (
    <p className="text-center">Dieser Coach hat noch keine Qualifikationen veröffentlicht.</p>
  )

  return (
    <Styles.Qualifications>
      <div className="headline">
        <BackButton onClick={onClose} />
        <p className="font-bold text-center">Meine Qualifikationen</p>
      </div>
      <div className="qualifications-list">
        {qualifications.map(({ qualification, qualificationId, year }) => (
          <div key={qualificationId} className="qualification">
            <span>Abschluss: {year}</span>
            <span>{qualification}</span>
          </div>
        ))}
      </div>
    </Styles.Qualifications>
  )
}

function LinktreeFooter ({ hidePartners }) {
  return (
    <Styles.LinktreeFooter>
      {!hidePartners ? (
        <>
          <div className="logo">
            <img src={FoodiaryLogo} alt="" />
          </div>
          <div className="partners">
            <p>Unterstütz durch:</p>
            <div className="partner-logos">
              <span><img src={PartnerLogo1} alt="" /></span>
              <span><img src={PartnerLogo2} alt="" /></span>
              <span><img src={PartnerLogo3} alt="" /></span>
              <span><img src={PartnerLogo4} alt="" /></span>
              <span><img src={PartnerLogo5} alt="" /></span>
            </div>
          </div>
        </>
      ) : ''}
      <div className="links">
        <a href="http://foodiary.app/impressum/">Impressum</a>
        <a href="http://foodiary.app/terms/">AGB</a>
        <a href="https://foodiary.app/partner/coach/">Coach werden</a>
      </div>
    </Styles.LinktreeFooter>
  )
}
