import styled from "styled-components"

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
`

export const TextContent = styled.div`
  &.imageResponsive {
    img {
      max-width: 100%;
    }
  }

  
  @media (max-width: 991.99px) {
    order: 2;
  }
`

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`

export const PlanBoxIconBox = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const PlanBoxInformation = styled.span`
  color: #44554c;
  padding-left: 20px;
  padding-top: 10px;
  /* font-size: 1.1rem; */
  width: calc(100% - 40px);

  @media (max-width: 991.99px) {
    /* font-size: 18px; */
  }

  /* @media (max-width: 767.99px) {
    font-size: 14px;
  } */
`

export const ButtonWrap = styled.div`
  margin-top: 60px;
`

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media (min-width: 992px) {
    margin-top: 50px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
  }
`
