import React, { useState } from "react";
import * as F from "./lastingSuccessWithKnowledge.style";
import Chip from "components/General/formComponents/chip/Chip";
import Weekly from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-weekly.jpg";
import Bibliothek from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Bibliotheke.jpg";
import Academy from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Academy.jpg";
import VideoAcademy from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Video-Academy.jpg";
import OnlineKurs from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Online-Kurs.jpg";

import WeeklyIcon from "assets/images/functions/Coach/weeklyIcon.svg";
import BibliothekIcon from "assets/images/functions/Coach/BibliothekIcon.svg";
import AcademyIcon from "assets/images/functions/Coach/AcademyIcon.svg";
import VideoAcademyIcon from "assets/images/functions/Coach/VideoAcademyIcon.svg";
import MoreIcon from "assets/images/functions/Coach/more.svg";

const Data = [
  {
    id: 1,
    title: "Weekly’s",
    description: "Wöchentliche Calls mit einem Ernährungsberater",
    image: Weekly,
    icon: WeeklyIcon,
    class: "",
  },
  {
    id: 2,
    title: "Bibliothek",
    description: "Schnelle Hilfe bei allen Ernährungsfragen",
    image: Bibliothek,
    icon: BibliothekIcon,
    class: "",
  },
  {
    id: 3,
    title: "Academy",
    description: "Ausführliche Artikel rund um das Thema Ernährung",
    image: Academy,
    icon: AcademyIcon,
    class: "",
  },
  {
    id: 4,
    title: "Video Academy",
    description: "Hilfreiche Videos für alle Ernährungsbereiche",
    image: VideoAcademy,
    icon: VideoAcademyIcon,
    class: "",
  },
  {
    id: 5,
    title: "Online Kurs",
    description: "Alle Ernährungsbereiche verstehen lernen",
    image: OnlineKurs,
    icon: WeeklyIcon,
    class: "",
  },
  {
    id: 6,
    title: "und vieles mehr",
    description: "Hilfreiche Videos für alle Ernährungsbereiche",
    image: null,
    icon: MoreIcon,
    class: "hover-none",
  },
];

const LastingSuccessWithKnowledge = () => {
  // const [SelectedImage, setSelectedImage] = useState(Weekly);
  const [SelectedItem, setSelectedItem] = useState(Data[0]);

  return (
    <F.Wrap>
      <Chip text="FUNKTIONEN" margin="0 auto 3rem" />
      <F.SectionTitle className="ct-text-block color-dark text-left text-4xl">
        <b>ICH VERMITTLE DIR DAS WISSEN,</b>
        <br />
        DAMIT DU DAUERHAFT ERFOLGE FEIERN KANNST
      </F.SectionTitle>
      <F.SectionTitleMobile className="ct-text-block color-dark text-left text-4xl">
        <b>ICH VERMITTLE DIR DAS WISSEN, </b> DAMIT DU DAUERHAFT ERFOLGE FEIERN
        KANNST
      </F.SectionTitleMobile>
      <F.GridContainer className="mt-12">
        <F.VisualContent>
          <img src={SelectedItem?.image} alt="icon" />
        </F.VisualContent>
        <F.TextContent>
          {Data.map((item, i) => (
            <F.Card
              key={i}
              onClick={() => {
                if (!item?.image) return;
                setSelectedItem(item);
              }}
              className={
                SelectedItem?.id === item?.id
                  ? `selected ${item.class}`
                  : item.class
              }
            >
              <img src={item?.icon} alt="icon" />
              <h6 className="ct-text-block font-medium color-dark text-base">
                {item.title}
              </h6>
              <p className="ct-text-block text-xs color-paragraph mt-2 mb-0">
                {item.description}
              </p>

              {SelectedItem?.id === item?.id && (
                <img
                  className="selected-img-on-mobile"
                  src={SelectedItem?.image}
                  alt="icon"
                />
              )}
            </F.Card>
          ))}
        </F.TextContent>
      </F.GridContainer>
    </F.Wrap>
  );
};

export default LastingSuccessWithKnowledge;
