import styled from "styled-components";

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`;

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
`;

export const Description = styled.p`
  color: #44554c;
  /* font-weight: 400;
  font-size: 1rem;
  margin: 50px 0 60px; */
`;

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const PlanBoxInformation = styled.span`
  color: #44554c;
  padding-left: 20px;
  padding-top: 5px;
  /* font-size: 1.1rem; */
  width: calc(100% - 40px);

  @media (max-width: 991.99px) {
    /* font-size: 18px; */
  }

  /* @media (max-width: 767.99px) {
    font-size: 14px;
  } */
`;

export const SurveyForm = styled.div``;

export const SurveyFormCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 1.5625rem;
  padding: 3rem;

  .inner-box {
    padding: 6rem 5rem;
    background: #e7faf3;
    border-radius: 1.5625rem;
  }
`;

export const SurveyFormTitle = styled.h6`
  /* font-size: 1.6rem;
  line-height: 2.2rem; */
  text-align: center;
  color: #142c1f;
  margin-bottom: 50px;
`;

export const Statusbar = styled.div`
  font-size: 2.3rem;
  color: #1a917d;
  margin-bottom: 20px;
  background: #e7faf3;
  padding: 18px 24px;
  border-radius: 8px;
  font-family: "RubikMedium";
`;

export const FormItem = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  color: #44554c;
  font-size: 18px;
  text-align: center;
  border: 2px solid transparent;
  cursor: pointer;
  font-family: "RubikRegular";

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ active }) =>
    active &&
    `
    border-color: #2ECF96;
  `}
`;

export const PhoneNumberInput = styled.input`
  width: 100%;
  border: 0;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  font-size: 18px;
  font-family: "RubikLight";
  margin-bottom: 10px;
  &:focus,
  &:hover {
    outline: 0;
  }

  &::placeholder {
    color: #a5b5af;
  }
`;

export const EmailInput = styled.input`
  width: 100%;
  border: 0;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  font-size: 18px;
  font-family: "RubikLight";
  margin-bottom: 10px;
  &:focus,
  &:hover {
    outline: 0;
  }

  &::placeholder {
    color: #a5b5af;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: 0;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  font-size: 18px;
  font-family: "RubikLight";
  resize: vertical;

  &:focus,
  &:hover {
    outline: 0;
  }

  &::placeholder {
    color: #a5b5af;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 60px;

  .btn-prev {
    width: 121px;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-family: "RubikRegular";
    cursor: pointer;
    background: transparent;
    color: #a5b5af;
  }
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
