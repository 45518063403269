import styled from "styled-components";

export const LeadFormContainer = styled.div`
  margin: 20px 0px;

  .step-description {
    text-align: center;
  }

  .step-header {
    display: flex;
    align-items: center;

    .step-title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
    }

    .step-title-form {
      text-align: left;
      padding-left: 10px;
    }

    .back-button {
      margin-left: -3px;
      margin-top: -10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`

export const StepContainer = styled.div`
  padding: 10px;
  
  .step {
    padding: 10px 5px;
    transition: all 200ms ease-in-out;

    > * {
      margin-top: 20px;
    }

    .form-select {
      background: #E7FAF3;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .form-option {
        background: white;
        margin: 5px 0px;
        padding: 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 200ms ease-in-out;
      }

      .active-item {
        border: 2px solid #2DCF96;
      }
    }
    
    .thankyou-body {
      background: #E7FAF3;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      > * {
        display: flex;
        align-items: flex-start;
        padding: 10px 0px;
        margin-bottom: 10px;

        > * {
          margin-right: 20px;
        }
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
    
    .form-lead-info {
      background: #E7FAF3;
      padding: 20px;
      border-radius: 10px;

      .error {
        color: red;
        text-align: center;
      }

      .policy {
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        input {
          margin-right: 10px;
        }
      }

      form {
        display: flex;
        flex-direction: column;

        .error {
          color: red;
          text-align: left;
        }

        > div {
          display: flex;
          flex-direction: column;
          padding: 10px 0px;

          input {
            background: white;
            border: none;
            outline: none;
            padding: 20px 10px;
            border-radius: 10px;
          }
        }
      }
    }
  }
`
