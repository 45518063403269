import React, { useEffect, useState } from "react";
import { NavLink, useHistory, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import * as C from "./StickyNavbar.style";
import * as CONSTANT from "constants";
import NavbarAccordians from "components/NavbarAccordians/NavbarAccordians";
// Images
import MenuIcon from "assets/images/menu-icon.svg";
import CrossIcon from "assets/images/cross.svg";
import Logo from "assets/images/Foodiary-Logo.png";
import IconDownload from "assets/images/navbar/Icon-mobile.svg";
import IconLogin from "assets/images/navbar/Icon-Log-in.svg";

import ArrowCircle from "assets/images/arrow-circle.svg";

const Menu = (
  title,
  link,
  isRelative = false,
  subMenuItems = [],
  button = null
) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = location.search;

  return (
    <div className="foodiary-menu">
      {!isRelative && (
        <span
          className="foodiary-menu-trigger foodiary-menu-title menu-item"
          onClick={() => {
            window.open(link + queryParams);
          }}
        >
          {title}
        </span>
      )}

      {isRelative && (
        <NavLink
          className="foodiary-menu-trigger foodiary-menu-title menu-item"
          activeClassName="active-nav-link"
          to={link + queryParams}
          exact={true}
        >
          {title}
        </NavLink>
      )}

      {subMenuItems.length > 0 && (
        <div className="foodiary-menu-box">
          <div className="foodiary-menu-box-child">
            {subMenuItems.map((item, i) => {
              if (item?.isRelative) {
                return (
                  <NavLink
                    to={item?.link + queryParams}
                    exact={true}
                    activeClassName="active-nav-link"
                    className="router-nav-link menu-item-link menu-item"
                    key={i}
                  >
                    {item?.name}
                  </NavLink>
                );
              } else if (item?.isButton) {
                return (
                  <React.Fragment key={i}>
                    <div className="dropdown-divider"></div>
                    <a
                      href={item?.link + queryParams}
                      target="_blank"
                      rel="noreferrer"
                      className="submenu-item-button w-full"
                    >
                      {item?.name}
                    </a>
                  </React.Fragment>
                );
              } else {
                return (
                  <a
                    href={item?.link}
                    key={i}
                    className="menu-item-link menu-item"
                  >
                    {item?.name}
                  </a>
                );
              }
            })}
            {button && (
              <div className="menu-item-button-wrap">
                <button
                  className="ct-link-text foodiary-outline-button w-100"
                  onClick={() => {
                    window.open(button?.link, "_blank");
                  }}
                >
                  {button?.title}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Navbar = ({ openSurveyModal }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [image, setImage] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const coach = useSelector((state) => state.coach.coach);

  const location = useLocation();
  const queryParams = location.search;

  const onToggle = (question) => {
    setImage(question?.img);
    setActiveQuestion(question);
  };

  useEffect(() => {
    window.addEventListener("scroll", getSticky);
    return () => {
      window.removeEventListener("scroll", getSticky);
    };
  }, []);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMobileMenu]);

  const getSticky = (e) => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  return (
    <>
      <C.Container>
        <C.NavbarWrapper
          className="pl-8 p-5"
          isSticky={isSticky}
          showMobileMenu={showMobileMenu}
        >
          <C.Navbar isSticky={isSticky} showMobileMenu={showMobileMenu}>
            <NavLink to="/" exact={true}>
              <C.Logo src={Logo} alt="logo" />
            </NavLink>
            <C.NavItems>
              <li>
                <NavLink
                  to={"/mein-coaching" + queryParams}
                  exact
                  activeClassName="active-nav-link"
                  className="router-nav-link menu-item"
                >
                  Mein Angebot
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/online-kurs" + queryParams}
                  exact
                  activeClassName="active-nav-link"
                  className="router-nav-link menu-item"
                >
                  Online-Kurs
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/app-erstellen"
                  exact
                  activeClassName="active-nav-link"
                >
                  App-Erstellen
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/erstgespraech"
                  exact
                  activeClassName="active-nav-link"
                  className="router-nav-link menu-item"
                >
                  Erstgespräch
                </NavLink>
              </li> */}
              <li>
                {Menu(
                  CONSTANT.App.title,
                  CONSTANT.App.link,
                  true,
                  CONSTANT.App.items
                )}
              </li>

              <li>
                {Menu(
                  CONSTANT.Coaching.title,
                  CONSTANT.Coaching.link,
                  true,
                  CONSTANT.Coaching.items
                )}
              </li>

              <li>
                {/* <PopupButton
                  className="ct-link-text foodiary-green-button cursor-pointer mt-12"
                  styles={{ margin: "0 0 0 1rem" }}
                  url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
                  rootElement={document.getElementById("root")}
                  text="Termin buchen"
                /> */}

                {/* <NavLink
                  to={"/kennenlernen" + queryParams}
                  exact
                  activeClassName="active-nav-link"
                  className="ct-link-text foodiary-green-button cursor-pointer"
                >
                  Termin buchen
                </NavLink> */}
                <button
                  className="ct-link-text foodiary-green-button cursor-pointer"
                  onClick={openSurveyModal}
                >
                  Termin vereinbaren
                </button>
              </li>
              <li>
                <img
                  style={{ width: "29px", cursor: "pointer" }}
                  src={ArrowCircle}
                  alt="arrow"
                  onClick={() => {
                    window.open("https://my.foodiary.app", "_blank");
                  }}
                />
              </li>
            </C.NavItems>
            {/* <PopupButton
            className="btn-secondary rounded"
            url="https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1&text_color=303030&primary_color=1c9d87"
            rootElement={document.getElementById("root")}
            text="Termin vereinbaren"
          /> */}
            <C.MobileNavItems>
              {!showMobileMenu && (
                <img
                  className="menu-icon"
                  src={MenuIcon}
                  alt="menu"
                  onClick={() => {
                    setShowMobileMenu(!showMobileMenu);
                  }}
                />
              )}
              {showMobileMenu && (
                <img
                  className="cross-icon"
                  src={CrossIcon}
                  alt="cross"
                  onClick={() => {
                    setShowMobileMenu(!showMobileMenu);
                  }}
                />
              )}
              <img
                style={{
                  width: "30px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                src={ArrowCircle}
                alt="arrow"
                onClick={() => {
                  window.open("https://my.foodiary.app/", "_blank");
                }}
              />
            </C.MobileNavItems>
          </C.Navbar>

          {showMobileMenu && (
            <C.MobileNavbar>
              <C.NavbarItemsWrapper>
                <C.MobileNavbarItems>
                  <li>
                    <NavLink
                      to={"/mein-coaching" + queryParams}
                      exact
                      activeClassName="active-nav-link"
                      className="router-nav-link menu-item"
                    >
                      Mein Angebot
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/online-kurs" + queryParams}
                      exact
                      activeClassName="active-nav-link"
                      className="router-nav-link menu-item"
                    >
                      Online-Kurs
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/online-kurs"
                      exact
                      activeClassName="active-nav-link"
                      className="router-nav-link menu-item"
                    >
                      Online-Kurs
                    </NavLink>
                  </li> */}

                  <NavbarAccordians
                    data={CONSTANT.NavbarMenusOnMobile}
                    activeQuestion={activeQuestion}
                    onExpanded={(question) => onToggle(question)}
                    titleShouldHaveLink
                  />
                  <li>
                    <C.Divider />
                  </li>
                  {/* <li>
                    <a href="https://www.foodiary.app/get-the-app/">
                      <C.AppDownloadItem>
                        <img src={IconDownload} alt="comment" /> App
                        herunterladen
                      </C.AppDownloadItem>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="https://my.foodiary.app/">
                      <C.LoginItem>
                        <img src={IconLogin} alt="comment" /> Login
                      </C.LoginItem>
                    </a>
                  </li> */}
                </C.MobileNavbarItems>

                {/* <PopupButton
                  className="ct-link-text foodiary-green-button cursor-pointer mt-2 w-full"
                  url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
                  rootElement={document.getElementById("root")}
                  text="Termin buchen"
                /> */}

                {/* <NavLink
                  to={"/kennenlernen" + queryParams}
                  exact
                  activeClassName="active-nav-link"
                  className="ct-link-text foodiary-green-button cursor-pointer"
                >
                  Termin buchen
                </NavLink> */}

                <button
                  className="ct-link-text foodiary-green-button cursor-pointer"
                  onClick={openSurveyModal}
                >
                  Termin vereinbaren
                </button>
              </C.NavbarItemsWrapper>
            </C.MobileNavbar>
          )}
        </C.NavbarWrapper>
      </C.Container>
    </>
  );
};

export default Navbar;
