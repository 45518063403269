import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import HappyToStart from "components/HappyToStart/HappyToStart";
import NextSteps from "components/NextSteps/NextSteps";
import FooterNew from "components/FooterNew/FooterNew";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation";

// Styles
import * as G from "styles/General.Styles";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";

const MyCoaching = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // const URL = "https://felix.foodiary.app/successful-booking-pro-r&narim0ken8".split('.app')[0] + ".app"
    const URL = window.location.href.split(".app")[0] + ".app";
    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <G.Container>
        <StickyNavbar
          openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
        />
        <SectionWrapper margin="13rem 0 0" padding="13rem 0 0">
          <HappyToStart />
        </SectionWrapper>
        <SectionWrapper margin="13rem 0 0">
          <ScheduleInitialConsultation page="success" />
        </SectionWrapper>
        <SectionWrapper margin="100px 0">
          <NextSteps />
        </SectionWrapper>
      </G.Container>
      <FooterNew />
      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default MyCoaching;
