import { takeLatest, put, all, call } from "redux-saga/effects";
import Tap from "@tapfiliate/tapfiliate-js";
import ApiCollections from "configs/services/apiCollections";

import CoachActionTypes from "redux/coach/coach.types";
import * as CoachActions from "./coach.actions";

/*************************************************/

export function* getTrainerID({ payload }) {
  yield put(CoachActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getTrainerID, payload.formData);
    yield put(CoachActions.trainerIdSuccess(response));
    yield put(CoachActions.loadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(CoachActions.trainerIdFailure(error));
    yield put(CoachActions.loadingStop());
  }
}

export function* getCoachData({ payload }) {
  yield put(CoachActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getCoachData, payload.formData);
    yield put(CoachActions.coachDataSuccess(response));
    yield put(CoachActions.loadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(CoachActions.coachDataFailure(error));
    yield put(CoachActions.loadingStop());
  }
}

/*************************************************/

export function* saveTrainerLeads({ payload }) {
  yield put(CoachActions.saveTrainerLeadLoadingStart());
  try {
    const response = yield call(
      ApiCollections.saveTrainerLeads,
      payload?.payload
    );
    yield put(CoachActions.saveTrainerLeadSuccess(response));
    // Tap.init(process.env.REACT_APP_TAPFILIATE_ID);
    // Tap("customer", payload?.payload?.email, {}, (res) => {
    //   console.log("Res", res);
    // });
    yield put(CoachActions.saveTrainerLeadLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(CoachActions.saveTrainerLeadFailure(error));
    yield put(CoachActions.saveTrainerLeadLoadingStop());
    payload.callback && payload.callback(error);
  }
}

/*************************************************/

export function* coachSagas() {
  yield all([
    yield takeLatest(CoachActionTypes.TRAINER_ID_REQUEST, getTrainerID),
    yield takeLatest(CoachActionTypes.COACH_DATA_REQUEST, getCoachData),
    yield takeLatest(CoachActionTypes.TRAINER_LEADS_REQUEST, saveTrainerLeads),
  ]);
}

/*************************************************/
