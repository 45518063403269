import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./nextSteps.styled";

// Images
import NextStepsPng from "assets/images/pngs/Foodiary-Zoom.png";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";

const NextSetps = () => {

  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 3rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-semibold">
          Die nächsten Schritte
        </C.Title>
        <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Wähle dein kostenloses, 45 minütiges Ersgespräch mit mir, indem du
              über den Buchungskalender einen freien Termin auswählst.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Nach erfolgreicher Terminvereinbarung erhältst du direkt eine
              E-Mail. Hier findest du den Zugang zu dem Online-Meeting über Zoom
              mit mir.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              2 Tage vor unserem Termin erhältst du eine Bestätigungsmail. Wenn
              du diesen Termin bestätigst, dann findet er auch statt.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Erscheine bitte pünktlich im Call. Richte zuvor dein Ton- und die
              Video-Einstellungen ein. Teste diese zuvor, um einen reibungslosen
              Ablauf zu garantieren.
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
        <C.ButtonWrap>
          {/* <Button
            gradient="linear-gradient(102deg, #2ECF96 0%, #1C9D87 100%)"
            margin="0"
            absolute={{ width: "197px", height: "auto" }}
            buttonStyles={{
              fontSize: "20px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontFamily: "RubikRegular",
            }}
          >
            Coach werden
          </Button> */}
          {/* <PopupButton
            className="btn-primary"
            styles={{ margin: "0", fontSize: '20px' }}
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Termin buchen"
          /> */}
        </C.ButtonWrap>
      </C.TextContent>

      <C.VisualContent>
        <img src={NextStepsPng} alt="zoom" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default NextSetps;
