import React from "react";
import { useHistory } from "react-router-dom";
import Chip from "components/General/formComponents/chip/Chip";

import * as F from "./DeliciousHealth.style";
import FoodiaryErstgesprach from "assets/images/App/Rezepte-Foodiary.png";

const DeliciousHealth = () => {
  const history = useHistory();

  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="REZEPTE" margin="0 0 2rem 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Mit Foodiary geht gesund auch lecker
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Dein Ernährungsplan von Foodiary bietet dir über 1700 leckere und
          abwechslungsreiche Rezepte. Mamas Lieblingsgerichte sind auch kein
          Problem. Du kannst deine eigenen Rezepte ganz easy anlegen.
        </F.Description>
        <F.Description className="ct-text-block color-paragraph-alt text-base text-left mt-6">
          Alle Rezepte werden natürlich individuell auf deine Kalorien,
          Makronährwerte und Ernährungsform abgestimmt.
        </F.Description>
        <button
          className="ct-link-text foodiary-green-button cursor-pointer mt-8"
          onClick={() => history.push("/app-erstellen")}
        >
          Ernährungsplan erstellen
        </button>
      </F.ContentSection>

      <F.VisualContent>
        <img src={FoodiaryErstgesprach} alt="coach" />
        {/* <ReactPlayer
          url={"https://foodiary.app/data/rezepte-auswaehlen-foodiary.mp4"}
          playsinline
          playing
          muted
          loop
        />
        <div className="white-border" /> */}
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default DeliciousHealth;
