import React from "react";
import * as C from "./coachFromFoodiary.styled";
import Chip from "components/General/formComponents/chip/Chip";
import CoachingSinglePlan from "components/CoachingSinglePlan/CoachingSinglePlan";
// Images
import Garantie from "assets/images/myCoaching/icon-garantie.svg";
import ActiveUser from "assets/images/Page3/50k-nutzer.svg";

const CoachFromFoodiary = ({ onPaymentModal }) => {
  return (
    <C.CoachingGlanceContainer>
      <C.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 3rem 0" />
        <C.GlanceTitle className="ct-text-block font-bold color-dark text-left text-4xl">
          Zahlreiche Vorteile die begeistern 😎
        </C.GlanceTitle>
        <C.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Schluss mit dubiosen Diäten- und Ernährungsmythen. Wir kennen uns aus,
          wissen wie man eine Ernährungsumstellung richtig angeht und wir helfen
          dir diese dauerhaft durchzuführen, ohne sich im Alltag einzuschränken.
        </C.GlanceText>
        <C.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Nutze die Vorteile von Foodiary ganz flexibel und unverbindlich.
        </C.GlanceText>
        <C.GlanceTags>
          <img src={Garantie} alt="tag" />
          <img src={ActiveUser} alt="user" />
        </C.GlanceTags>
      </C.CoachingGlanceLeft>
      <C.PlansBox className="singleBox">
        <C.OnePlan>
          <CoachingSinglePlan />
        </C.OnePlan>
      </C.PlansBox>
    </C.CoachingGlanceContainer>
  );
};

export default CoachFromFoodiary;
